/* eslint-disable no-shadow */
import axios from 'axios'
import {
  getAllDelegacion,
} from '@/utils/paths'

const pathGetAllDelegacion = getAllDelegacion

export function delegacionResource() {
  function getAllDelegacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDelegacion,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }

  return {
    getAllDelegacion,
  }
}
