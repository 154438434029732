export const ataccDomainLogin = process.env.VUE_APP_ATACC_LOGIN_BACK_URL
export const ataccDomainResetPassword = process.env.VUE_APP_ATACC_RESET_PASSWORD_BACK_URL
export const ataccDomainItemMenu = process.env.VUE_APP_ATACC_ITEM_MENU_BACK_URL
export const ataccDomainRegister = process.env.VUE_APP_ATACC_REGISTER_BACK_URL
export const ataccDomainCredencial = process.env.VUE_APP_ATACC_CREDENCIAL_BACK_URL
export const ataccDomainAbm = process.env.VUE_APP_ATACC_ABM_BACK_URL
export const ataccDomainSolicitudes = process.env.VUE_APP_ATACC_SOLICITUDES_BACK_URL
// Login
export const postlogin = `${ataccDomainLogin}login`
// login - reset password
export const postEmailResetPassword = `${ataccDomainResetPassword}email/reset-password`
export const postRegister = `${ataccDomainRegister}register`
export const postRenewToken = `${ataccDomainLogin}login/renew-token`

// ABM Usuario
export const getByIdUsuario = `${ataccDomainAbm}usuario/`
export const postByUsuarioByFilter = `${ataccDomainAbm}usuario/filter`
export const getByUsuarioBySpecs = `${ataccDomainAbm}usuario/specs`
export const putActivarUsuario = `${ataccDomainAbm}usuario/activar/`
export const putDesactivarUsuario = `${ataccDomainAbm}usuario/desactivar/`
export const postSaveUsuario = `${ataccDomainAbm}usuario/`
export const putUpdateUsuario = `${ataccDomainAbm}usuario/`

// ABM Stock
export const getStockBySpecs = `${ataccDomainAbm}stock/producto/specs`
// export const putActivarUsuario = `${ataccDomainAbm}usuario/activar/`
// export const putDesactivarUsuario = `${ataccDomainAbm}usuario/desactivar/`
export const postSaveStock = `${ataccDomainAbm}stock/`
export const putUpdateStok = `${ataccDomainAbm}stock/`

// Roles
export const getAllRoles = `${ataccDomainAbm}rol/`

// EstadosUsuario
export const getAllUsuarioEstado = `${ataccDomainAbm}usuarioestado/`

// Delegacion
export const getAllDelegacion = `${ataccDomainAbm}delegacion/`

// Reportes

export const getReporteExcel = `${ataccDomainAbm}stock/reporte`
export const postReporteFechasStockExcel = `${ataccDomainAbm}stock/reporte/movimientos`
// ABM Configuración Beneficios
export const getBeneficioById = `${ataccDomainAbm}beneficio/`
export const postGetBeneficioFilter = `${ataccDomainAbm}beneficio/filter`
export const postSaveBeneficio = `${ataccDomainAbm}beneficio/`
export const putUpdateBeneficio = `${ataccDomainAbm}beneficio/`
export const getAllBeneficioTipo = `${ataccDomainAbm}beneficiotipo`

// Producto
export const postGetAllNotInThisList = `${ataccDomainAbm}producto/notinthislistids`

// Categoria solicitante
export const getAllCategoriaSolicitante = `${ataccDomainAbm}catsolic`

// Solicitud beneficios
export const getBeneficios = `${ataccDomainSolicitudes}solicitudes/beneficios/`
export const getBeneficioDetalle = `${ataccDomainSolicitudes}solicitudes/beneficioDetalle/`
export const saveSolicitudes = `${ataccDomainSolicitudes}solicitudes/`
export const saveSolicitud = `${ataccDomainSolicitudes}solicitudes/single`
export const getSolicitudesByBeneficioId = `${ataccDomainSolicitudes}solicitudes/`
export const postSolicitudesFilter = `${ataccDomainSolicitudes}solicitudes/filter/`
export const getAfiliado = `${ataccDomainSolicitudes}afiliado/`
export const getCatSolicitante = `${ataccDomainSolicitudes}catsolic/`
export const postUploadFileAttachment = `${ataccDomainSolicitudes}solicitudes/uploadfile`
export const postUploadFilesAttachment = `${ataccDomainSolicitudes}solicitudes/uploadfiles`
export const getFindInfoAdjuntos = `${ataccDomainSolicitudes}solicitudes/infoadjuntos/`
export const getAdjunto = `${ataccDomainSolicitudes}solicitudes/adjunto/`
export const getSolicitudEstado = `${ataccDomainSolicitudes}solicitudestado`
export const getBeneficiosSelect = `${ataccDomainSolicitudes}solicitudes/beneficio/basiclist`
export const getListaSelectBeneficios = `${ataccDomainSolicitudes}solicitudes/beneficios/listselect`
export const getListaSelectBeneficiosFromSolicitudes = `${ataccDomainSolicitudes}solicitudes/beneficios/listselectafi/`
export const postSaveSolicitudQuincho = `${ataccDomainSolicitudes}solicitudes/quincho`
export const getSolicitudesPreviasQuincho = `${ataccDomainSolicitudes}solicitudes/prevquincho/`
export const postVerificarYCompletarSolicitud = `${ataccDomainSolicitudes}solicitudes/verificarycompletar`

// Consulta Solicitud
export const postConsultaSolicitudFilter = `${ataccDomainSolicitudes}consultasolicitud/filter`
export const putSolicitudEstado = `${ataccDomainSolicitudes}consultasolicitud/`
export const postComentario = `${ataccDomainSolicitudes}consultasolicitud/`
export const postEntregaSolicitud = `${ataccDomainSolicitudes}consultasolicitud/entrega/`
export const postSaveEstadoUpdateComentario = `${ataccDomainSolicitudes}consultasolicitud/`
export const getReporteComprobanteSolicitud = `${ataccDomainSolicitudes}reporte/`
export const getExcelConsultaSolocitudes = `${ataccDomainSolicitudes}reporte/`
export const postNotificarRechazo = `${ataccDomainSolicitudes}email/`
