import Vue from 'vue'

import {
  BootstrapVue,
  BootstrapVueIcons,
  ToastPlugin,
  ModalPlugin,
} from 'bootstrap-vue'

import VueCompositionAPI from '@vue/composition-api'

import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

import axios from 'axios'
import {
  responseErrorInterceptor,
  responseInterceptor,
  requestInterceptor,
  requestErrorInterceptor,
} from '@/axios/axios'

import {
  directivaPermiso,
} from 'trx-vue-common/src/directives/directivas'

import {
  loadLocalCredentials,
} from 'trx-vue-common/src/security/seguridad'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import MixinsUtils from '@/utils/mixinsUtils'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// PWA
import './registerServiceWorker'

Vue.directive('permiso', directivaPermiso)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.mixin(MixinsUtils)
window.mixin = MixinsUtils
// vee-vallidate
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Font Awesome
library.add(fas, far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

/*
import {
  loadSecurityFromLocalStorage,
} from 'trx-vue-common/src/security/seguridad'
loadSecurityFromLocalStorage(store, router)
*/
loadLocalCredentials(store)

axios.interceptors.request.use(axiosConfig => requestInterceptor(axiosConfig), requestErrorInterceptor)
axios.interceptors.response.use(responseInterceptor, error => responseErrorInterceptor(error, router))

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
